import { Component, WritableSignal, signal } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import countries from '../core/consts/country.const';
import { LeadService } from '../core/services/leads.service';
import { validPatternCategory2 } from '../core/validators/pattern-with-msg.validator';
import { emailRegex } from '../core/consts/regex.const';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastService } from '../core/services/toast.service';
import { numberOfEmployees } from '../core/consts/organization.const';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('popInOut', [
      state('in', style({ transform: 'scale(1)' })),
      transition(':enter', [
        style({ transform: 'scale(0)' }),
        animate('300ms ease-in')
      ]),
    ])
  ]
})
export class SignupComponent {
  signupSuccessful: WritableSignal<boolean> = signal(false);
  countries = countries;
  numberOfEmployeesConst = numberOfEmployees;
  signupForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z]*$/), Validators.maxLength(50)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z]*$/), Validators.maxLength(50)]),
    email: new FormControl('', [Validators.required, Validators.pattern(emailRegex), Validators.maxLength(50)]),
    contactCountryCode: new FormControl([], [Validators.required, Validators.maxLength(5)]),
    contactNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
    optInForWhatsappUpdates: new FormControl(false),
    organizationName: new FormControl('', [Validators.required, validPatternCategory2(), Validators.maxLength(50)]),
    numberOfEmployees: new FormControl([], [Validators.required]),
    country: new FormControl<string[]>([], [Validators.required, Validators.maxLength(50)]),
    acceptTermsAndConditions: new FormControl(false, [Validators.required]),
  });

  constructor(
    readonly fb: FormBuilder,
    readonly leadService: LeadService,
    readonly toastService: ToastService,
  ) {}

  formControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }

  onSignUp() {
    if (this.signupForm.invalid) {
      console.log(' -- signup form ', this.signupForm);
      this.signupForm.markAllAsTouched();
      return;
    }

    this.leadService.createLead({
      ...this.signupForm.value,
      country: this.signupForm.value.country?.[0],
      contactCountryCode: this.signupForm.value.contactCountryCode?.[0],
      numberOfEmployees: this.signupForm.value.numberOfEmployees?.[0],
    }).subscribe({
      next: (res) => {
        console.log(' -- res ', res);
        this.signupSuccessful.set(true);
      },
      error: (err) => {
        console.log(' -- err ', err);
        this.toastService.showAPIErrorToast(err);
      },
    });
  }
}
