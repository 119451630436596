import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Auth, RefreshAccessToken, User } from '../models/auth.model';
import { IEmptyResponse, ISuccessResponse } from '../models/common.model';
import { Urls } from '../urls';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(readonly http: HttpClient) {}
  login(data: { email: string; password: string }): Observable<Auth> {
    return this.http
      .post<ISuccessResponse<Auth>>(`${Urls.login}`, data, {
        withCredentials: true,
      })
      .pipe(
        map((res: ISuccessResponse<Auth>) => {
          return res.data;
        }),
      );
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${Urls.logout}`, {});
  }

  refreshAccessToken(): Observable<RefreshAccessToken> {
    return this.http
      .post<ISuccessResponse<RefreshAccessToken>>(
        `${Urls.refreshAccessToken}`,
        {
          expAccessToken: this.getToken(),
        },
        { withCredentials: true },
      )
      .pipe(map((res) => res.data));
  }

  forgotPassword(data: { email: string }): Observable<IEmptyResponse> {
    return this.http.post<IEmptyResponse>(`${Urls.forgotPassword}`, data);
  }

  resetPassword(data: { password: string; t: string; }): Observable<IEmptyResponse> {
    return this.http.post<IEmptyResponse>(`${Urls.resetPassword}`, data);
  }

  /**
   * set token in localstorage
   * @param token - is access token
   * @type {(token: string) => void}
   * @return {void}
   */
  setToken(token: string): void {
    localStorage.setItem('hs-at', token);
  }

  /**
   * get token from localstorage
   * @return {string}
   */
  getToken(): string {
    return localStorage.getItem('hs-at') as string;
  }

  setAccessTokenExpiry(exp: number): void {
    localStorage.setItem('hs-at-exp', exp.toString());
  }

  getAccessTokenExpiry(): number {
    return parseInt(localStorage.getItem('hs-at-exp') as string, 10);
  }

  /**
   * set user details in localstorage
   * @param user - user details
   * @type {(user: User) => void}
   * @return {void}
   */
  setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * get user details from localstorage
   * @return {User | void}
   */
  getUser(): User | void {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user') as string);
    }
  }
}
