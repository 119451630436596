import { ModuleWithProviders, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { ToastComponent } from './toast.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  defaultToastConfig,
  TOAST_CONFIG_TOKEN,
  ToastService,
} from '../../core/services/toast.service';

@NgModule({
  imports: [OverlayModule, BrowserModule, BrowserAnimationsModule],
  declarations: [ToastComponent],
  providers: [ToastService],
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<any> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    };
  }
}
