import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonSpinnerComponent } from '../ui-components/button-spinner/button-spinner.component';
import { DialogComponent } from '../ui-components/dialog/dialog.component';
import { PaginationComponent } from '../ui-components/pagination/pagination.component';
import { SelectComponent } from '../ui-components/select/select.component';
import { RippleDirective } from './directives/ripple.directive';
import { SafePipe } from './pipes/safe.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkListbox, CdkListboxModule, CdkOption } from '@angular/cdk/listbox';
import { PasswordStrengthComponent } from '../ui-components/password-strength/password-strength.component';
import { DialogModule } from '@angular/cdk/dialog';
import { LoaderComponent } from '../ui-components/loader/loader.component';
import { ToastService } from './services/toast.service';
import { FilterComponent } from '../ui-components/filter/filter.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { OrganizationFormComponent } from '../organization/organization-details/organization-form/organization-form.component';
import { TooltipModule } from '../ui-components/tooltip-container/tooltip.module';

@NgModule({
  declarations: [
    DialogComponent,
    PaginationComponent,
    ButtonSpinnerComponent,
    SafePipe,
    RippleDirective,
    SelectComponent,
    ShortNamePipe,
    PasswordStrengthComponent,
    LoaderComponent,
    FilterComponent,
    OrganizationFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    CdkListbox,
    CdkOption,
    CdkListboxModule,
    DialogModule,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    TooltipModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    ReactiveFormsModule,
    RippleDirective,
    DialogComponent,
    PaginationComponent,
    ButtonSpinnerComponent,
    SafePipe,
    SelectComponent,
    ShortNamePipe,
    OverlayModule,
    PortalModule,
    CdkListbox,
    CdkOption,
    CdkListboxModule,
    PasswordStrengthComponent,
    DialogModule,
    LoaderComponent,
    FilterComponent,
    OrganizationFormComponent,
    TooltipModule,
  ],
  providers: [ToastService],
})
export class SharedModule {}
