import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IEmptyResponse, ISuccessResponse } from '../models/common.model';
import { AcceptInviteResponse, PreCheckResponse, UserInvite } from '../models/invite.model';
import { Urls } from '../urls';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(readonly http: HttpClient) {}

  preCheck(orgId: string, email: string): Observable<PreCheckResponse> {
    return this.http
      .get<ISuccessResponse<PreCheckResponse>>(
        `${Urls.org}${orgId}/invite/pre?email=${email}`,
      )
      .pipe(map((res) => res.data));
  }

  invite(orgId: string, data: UserInvite): Observable<IEmptyResponse> {
    return this.http.post<IEmptyResponse>(`${Urls.org}${orgId}/invite`, data);
  }

  resendInvite(orgId: string, userId: string): Observable<IEmptyResponse> {
    return this.http.post<IEmptyResponse>(`${Urls.org}${orgId}/invite/resend`, {
      userId,
    });
  }

  cancelInvite(orgId: string, userId: string): Observable<IEmptyResponse> {
    return this.http.post<IEmptyResponse>(`${Urls.org}${orgId}/invite/cancel`, {
      userId,
    });
  }

  acceptInvite(inviteId: string, token: string): Observable<AcceptInviteResponse> {
    return this.http
      .post<ISuccessResponse<AcceptInviteResponse>>(
        `${Urls.invite}${inviteId}?t=${token}`,
        {},
      )
      .pipe(map((res) => res.data));
  }
}
