import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  TOAST_CONFIG_TOKEN,
  ToastConfig,
  ToastData,
} from '../../core/services/toast.service';
import { ToastRef } from './toat-ref';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 1 }),
        animate('300ms ease-out'),
      ]),
      transition(':leave', [
        animate(
          '300ms ease-out',
          style({ transform: 'translateY(100%)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() htmlTemplate!: TemplateRef<any>;
  @Input() title!: string;
  @Input() text!: string;
  iconType: 'success' | 'error' | 'info' | 'warning';

  private intervalId!: number;

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) readonly toastConfig: ToastConfig,
  ) {
    this.iconType = data.type;
  }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.close(), 5000);
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    clearTimeout(this.intervalId);
    this.ref.close();
  }
}
