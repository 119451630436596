/** Roles for app panel */
export enum AppRoles {
  /** Owner of the organization with full access of each organization module */
  ADMIN = 'Admin',
  CUSTOM = 'custom',
}

export enum AdminRoles {
  SUPER_ADMIN = 'SA',
}
