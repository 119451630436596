import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  IEmptyResponse,
  IPaginationResponse,
  ISuccessResponse,
} from '../models/common.model';
import {
  LeadApproveStep1,
  LeadApproveStep2,
  LeadCreate,
  LeadDetails,
  LeadList,
  LeadPaginateOptions,
} from '../models/leads.model';
import { IPaginateData } from '../models/pagination.model';
import { Urls } from '../urls';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private newLeads = new BehaviorSubject<number>(0);

  constructor(readonly http: HttpClient) {}

  getNewLeadsCount(): Observable<number> {
    return this.newLeads.asObservable();
  }

  setNewLeadsCount(count: number): void {
    this.newLeads.next(count);
  }

  refreshNewLeadCount() {
    this.http
      .get<ISuccessResponse<{ count: number }>>(`${Urls.leadNewCount}`)
      .pipe(map((res) => res.data))
      .subscribe(res => {
        this.setNewLeadsCount(res.count);
      });
  }

  getLeads(data: LeadPaginateOptions): Observable<IPaginateData<LeadList>> {
    return this.http
      .post<IPaginationResponse<LeadList>>(`${Urls.leadList}`, data)
      .pipe(map((res) => res.data));
  }

  /** Signup - public route */
  createLead(data: LeadCreate): Observable<IEmptyResponse> {
    return this.http.post<IEmptyResponse>(`${Urls.leads}`, data);
  }

  deleteById(leadId: string): Observable<IEmptyResponse> {
    return this.http.delete<IEmptyResponse>(`${Urls.leads}${leadId}`);
  }

  getLeadDetailsById(leadId: string): Observable<LeadDetails> {
    return this.http
      .get<ISuccessResponse<LeadDetails>>(`${Urls.leads}${leadId}`)
      .pipe(
        map((res: ISuccessResponse<LeadDetails>) => {
          return res.data;
        }),
      );
  }

  approveStep1(
    leadId: string,
    data: LeadApproveStep1,
  ): Observable<{ orgId: string }> {
    return this.http
      .put<ISuccessResponse<{ orgId: string }>>(
        `${Urls.leads}${leadId}/approve/org-step`,
        data,
      )
      .pipe(map((res) => res.data));
  }

  approveStep2(
    leadId: string,
    data: LeadApproveStep2,
  ): Observable<{ userId: string }> {
    return this.http
      .put<ISuccessResponse<{ userId: string }>>(
        `${Urls.leads}${leadId}/approve/admin-step`,
        data,
      )
      .pipe(
        map((res: ISuccessResponse<{ userId: string }>) => {
          return res.data;
        }),
      );
  }

  reject(leadId: string, reason?: string): Observable<IEmptyResponse> {
    return this.http.patch<IEmptyResponse>(`${Urls.leads}${leadId}/reject`, {
      reason,
    });
  }
}
