import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { AuthService } from '../core/services/auth.service';
import { InviteService } from '../core/services/invite.service';
import { ToastService } from '../core/services/toast.service';
import { passwordMatchValidator } from '../core/validators/password-match.validator';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit, OnDestroy {
  inviteId = '';
  token = '';
  inviteAcceptedSuccessfully = signal(false);

  // If invited user is new user
  newUserPasswordToken = '';
  setPasswordForm: FormGroup = this.fb.group({
    password: ['', [Validators.required]],
    matchPassword: [
      '',
      [
        Validators.required,
        passwordMatchValidator('password', 'matchPassword'),
      ],
    ],
  });
  isStrongPassword = true;
  showPassword = signal(false);
  passwordSubmitted = signal(false);
  countdown = 15; // 15 seconds countdown after password set successfully to redirect to login page
  countdownInterval: any;

  isLoading = signal(true);
  authenticating = signal(false);
  readonly unsubscribe = new Subject<void>();

  constructor(
    readonly route: ActivatedRoute,
    readonly fb: FormBuilder,
    readonly router: Router,
    readonly inviteService: InviteService,
    readonly authService: AuthService,
    readonly toastService: ToastService,
  ) {
    this.route.params.subscribe((params: any) => {
      this.inviteId = params.inviteId;
      console.log('-- invite id from route ', this.inviteId);
    });
    this.route.queryParams.subscribe((params: any) => {
      this.token = params.t;
      console.log('-- token from route ', this.token);
    });
  }

  async ngOnInit() {
    await this.acceptInvite();
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async acceptInvite() {
    console.log('-- accept invite', this.inviteId, this.token);
    this.isLoading.set(true);

    const acceptInvite = this.inviteService.acceptInvite(
      this.inviteId,
      this.token,
    );
    try {
      const res = await firstValueFrom(acceptInvite);
      console.log('-- accept invite res', res);
      this.isLoading.set(false);
      if (res.pt) {
        this.newUserPasswordToken = res.pt || '';
      }
      this.inviteAcceptedSuccessfully.set(true);
    } catch (err) {
      console.log('-- accept invite err', err);
      this.isLoading.set(false);
      this.inviteAcceptedSuccessfully.set(false);
    }
  }

  setPassword() {
    if (this.setPasswordForm.invalid) {
      this.setPasswordForm.markAllAsTouched();
      return;
    }
    this.authenticating.set(true);
    this.authService
      .resetPassword({
        password: this.setPasswordForm.get('password')?.value,
        t: this.newUserPasswordToken,
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res) => {
          this.authenticating.set(false);
          console.log(res);
          this.toastService.showToast({
            type: 'success',
            title: 'Success',
            text: 'Password set successfully',
          });
          this.passwordSubmitted.set(true);
          this.newUserPasswordToken = '';
          this.startCountdown();
        },
        error: (err) => {
          console.log(err);
          this.authenticating.set(false);
          this.toastService.showAPIErrorToast(err);
        },
      });
  }

  passwordStrength(event: boolean) {
    this.isStrongPassword = event;
  }

  passwordToggle() {
    this.showPassword.set(!this.showPassword());
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        if (this.countdown === 1) {
          this.isLoading.set(true);
        }
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval);
        this.redirectToLogin();
      }
    }, 1000);
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }
}
