<div *ngIf="!isLoading()" class="flex justify-center bg-[#f7f7f7] w-full h-full overflow-y-scroll">
  <div class="rounded-lg bg-white sm:shadow p-6 h-full sm:h-fit m-auto sm:w-[75%]">
    <div
      class="grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 sm:gap-14"
    >
      <div
        class="flex bg-[#f7f7f7] row-span-1 rounded-lg justify-center align-middle"
      >
        <img src="assets/images/mail-sent.svg" alt="email-invite" />
      </div>
      <div class="flex items-center">
        <div class="w-full">
          <ng-container *ngIf="inviteAcceptedSuccessfully()">
            <h3 class="font-semibold text-2xl">
              Invite Accepted
            </h3>
            <p class="text-gray-800 text-sm font-normal mt-2 mb-6" [ngClass]="{ 'mb-12': !newUserPasswordToken}">
              You've successfully accepted the invite.
              <ng-container *ngIf="newUserPasswordToken">
                Please set password for your new account.
              </ng-container>
            </p>
          </ng-container>
          <ng-container *ngIf="!inviteAcceptedSuccessfully()">
            <h3 class="font-semibold text-2xl">
              Invalid or Expired invite!
            </h3>
            <p class="text-gray-800 text-sm font-normal mt-2 mb-12">
              Looks like the invite you're trying to access is either expired or invalid. Please contact the admin for a new invite.
            </p>
          </ng-container>

          <ng-container *ngIf="newUserPasswordToken">
            <!-- <div class="p-4 mb-4 text-xs text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
              <span class="font-medium">Info alert!</span> Change a few things up and try submitting again.
              Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.
            </div> -->
            <div class="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <div>
                Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.
                <!-- <span class="font-medium">Info alert!</span> Change a few things up and try submitting again. -->
              </div>
            </div>
            <form
              [formGroup]="setPasswordForm"
              (ngSubmit)="setPassword()"
              class="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-4"
            >
              <div class="sm:col-span-2">
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >Password</label
                >
                <div>
                  <div class="relative">
                    <div
                      (click)="passwordToggle()"
                      aria-hidden="true"
                      class="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <img
                        class="cursor-pointer"
                        [src]="
                          'assets/images/icons/' +
                          (showPassword() ? 'eye-slash' : 'eye') +
                          '.svg'
                        "
                        height="20"
                        width="20"
                        alt=""
                      />
                    </div>
                    <input
                      [type]="showPassword() ? 'text' : 'password'"
                      id="password"
                      class="input pr-10"
                      [ngClass]="{
                        'input-invalid':
                        ((setPasswordForm.get('password')?.touched &&
                        setPasswordForm.get('password')?.invalid) || !isStrongPassword)
                      }"
                      placeholder="••••••••"
                      required
                      formControlName="password"
                    />
                  </div>
                  <app-password-strength
                    [passwordToCheck]="setPasswordForm.value.password"
                    (passwordStrength)="passwordStrength($event)"
                  ></app-password-strength>
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="matchPassword"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >Re-Enter Password</label
                >
                <div>
                  <input
                    type="password"
                    id="matchPassword"
                    class="input"
                    [ngClass]="{
                      'input-invalid':
                        setPasswordForm.get('matchPassword')?.touched &&
                        setPasswordForm.get('matchPassword')?.invalid
                    }"
                    placeholder="••••••••"
                    required
                    formControlName="matchPassword"
                  />
                </div>
                <p
                  class="input-err-msg"
                  *ngIf="
                    setPasswordForm.get('matchPassword')?.touched &&
                    setPasswordForm.get('matchPassword')?.hasError('required')
                  "
                >
                  Please re-enter password.
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    setPasswordForm.get('matchPassword')?.touched &&
                    !setPasswordForm.get('matchPassword')?.hasError('required') &&
                    setPasswordForm.get('matchPassword')?.hasError('notSame')
                  "
                >
                  Passwords do not match.
                </p>
              </div>
              <div class="sm:col-span-2 mt-2">
                <button
                  type="submit"
                  appRipple
                  [disabled]="authenticating()"
                  class="btn-primary w-full px-5 relative overflow-hidden"
                >
                  Set Password
                </button>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="passwordSubmitted()">
            <div *ngIf="countdown > 0" class="mb-2">
              Redirecting to login page in {{countdown}} seconds...
            </div>
          </ng-container>

          <a
            [routerLink]="['/login']"
            class="sm:col-span-2 mt-6 text-gray-400 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M16.0306 10.0003H4.36389M4.36389 10.0003L10.1972 15.8337M4.36389 10.0003L10.1972 4.16699"
                stroke="#374151"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="ml-2 text-sm font-semibold text-gray-700"
              >Go to log in</span
            >
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-between text-gray-700 text-base font-normal pt-6">
      <img src="assets/images/icons/whatsapp.svg" alt="whatsapp" />
      <span>Privacy Policy | Help & Support</span>
    </div>
  </div>
</div>
<app-loader *ngIf="isLoading()"></app-loader>
