import { Component, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRoles } from '../core/consts/role.const';
import { Auth } from '../core/models/auth.model';
import { AuthService } from '../core/services/auth.service';
import { ToastService } from '../core/services/toast.service';
import { passwordMatchValidator } from '../core/validators/password-match.validator';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  protected fcLoginEmail = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  path!: string;
  loginForm: FormGroup = this.fb.group({
    email: this.fcLoginEmail,
    password: ['', [Validators.required]],
    rememberMe: [false],
  });
  authenticating = signal(false);
  showPassword = signal(false);
  forgotPasswordForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  forgotPasswordSubmitted = signal(false);
  resetPasswordForm: FormGroup = this.fb.group({
    password: ['', [Validators.required]],
    matchPassword: ['', [Validators.required, passwordMatchValidator('password', 'matchPassword')]],
  });
  isStrongPassword = true;
  resetPasswordSubmitted = signal(false);

  constructor(
    readonly route: ActivatedRoute,
    readonly authService: AuthService,
    readonly router: Router,
    private fb: FormBuilder,
    private toastService: ToastService,
  ) {
    this.path = this.route.snapshot.routeConfig?.path
      ? this.route.snapshot.routeConfig?.path
      : 'login';
  }

  /**
   * show and hide password
   */
  passwordToggle() {
    this.showPassword.set(!this.showPassword());
  }

  /**
   * login to panel
   */
  doLogin() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    this.loginForm.markAllAsTouched();
    this.authenticating.set(true);
    this.authService.login(this.loginForm.value).subscribe({
      next: (res: Auth) => {
        if (res?.user?.adminRole !== AdminRoles.SUPER_ADMIN) {
          this.toastService.showToast({
            type: 'error',
            title: 'Unauthorized',
            text: 'You are not allowed to login here',
          });
          this.loginForm.reset();
          this.authenticating.set(false);
          return;
        }
        this.authService.setUser(res.user);
        this.authService.setToken(res.accessToken);
        this.authenticating.set(false);
        this.router.navigate(['/']);
      },
      error: (err) => {
        console.log(err);
        this.toastService.showAPIErrorToast(err);
        this.authenticating.set(false);
      },
    });
  }

  forgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    this.authenticating.set(true);
    this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe({
      next: () => {
        this.authenticating.set(false);
        this.forgotPasswordSubmitted.set(true);
      },
      error: (err) => {
        console.log(err);
        this.authenticating.set(false);
        this.toastService.showAPIErrorToast(err);
      },
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    this.authenticating.set(true);
    this.authService
      .resetPassword({
        password: this.resetPasswordForm.get('password')?.value,
        t: this.route.snapshot.queryParams['t'],
      })
      .subscribe({
        next: () => {
          this.authenticating.set(false);
          this.resetPasswordSubmitted.set(true);
        },
        error: (err) => {
          console.log(err);
          this.authenticating.set(false);
          this.toastService.showAPIErrorToast(err);
        },
      });
  }

  passwordStrength(event: boolean) {
    this.isStrongPassword = event;
  }
}
