import { AfterViewInit, Component, OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'admin-panel';
  isLoading = false;

  constructor(private router: Router) {}
  ngOnInit(): void {
    initFlowbite();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/') {
          this.isLoading = true;
        }
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        if (event.url === '/') {
          this.isLoading = false;
        }
      }
    });
  }
}
