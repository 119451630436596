<div class="flex justify-center w-full h-full">
  <div class="p-6 h-full w-full">
    <div class="grid grid-rows-1 grid-cols-1 gap-14 h-full">
      <div
        class="col-end-1 hidden sm:flex bg-[#f7f7f7] rounded-lg justify-center align-middle"
      >
        <img src="../../assets/images/login.svg" alt="signup" />
      </div>
      <div class="col-end-2 flex flex-col justify-center relative">
        <div class="sm:w-2/3 w-full">
          <div *ngIf="!signupSuccessful()">
            <h3 class="font-semibold text-2xl">
              Unlock the Future of Conversations
            </h3>
            <p class="mt-2 text-gray-800 text-base font-normal">
              Register to Supercharge Your CX
            </p>

            <form
              [formGroup]="signupForm"
              (ngSubmit)="onSignUp()"
              class="mt-4 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-4"
            >
              <div>
                <label
                  for="first_name"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >First name</label
                >
                <input
                  type="text"
                  id="first_name"
                  class="input"
                  [ngClass]="{
                    'input-invalid':
                      signupForm.get('firstName')?.touched &&
                      signupForm.get('firstName')?.invalid
                  }"
                  placeholder="John"
                  formControlName="firstName"
                  autocomplete="off"
                  required
                />
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('firstName')?.touched &&
                    signupForm.get('firstName')?.hasError('required')
                  "
                >
                  First Name is required
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('firstName')?.touched &&
                    signupForm.get('firstName')?.hasError('pattern')
                  "
                >
                  Only alphabets are allowed (A-Z a-z)
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('firstName')?.touched &&
                    signupForm.get('firstName')?.hasError('maxlength')
                  "
                >
                  Max 50 characters are allowed
                </p>
              </div>
              <div>
                <label
                  for="last_name"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >Last name</label
                >
                <input
                  type="text"
                  id="last_name"
                  class="input"
                  [ngClass]="{
                    'input-invalid':
                      signupForm.get('lastName')?.touched &&
                      signupForm.get('lastName')?.invalid
                  }"
                  placeholder="Doe"
                  formControlName="lastName"
                  autocomplete="off"
                  required
                />
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('lastName')?.touched &&
                    signupForm.get('lastName')?.hasError('required')
                  "
                >
                  Last Name is required
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('lastName')?.touched &&
                    signupForm.get('lastName')?.hasError('pattern')
                  "
                >
                  Only alphabets are allowed (A-Z a-z)
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('lastName')?.touched &&
                    signupForm.get('lastName')?.hasError('maxlength')
                  "
                >
                  Max 50 characters are allowed
                </p>
              </div>
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >Email</label
                >
                <div class="relative">
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                    <img
                      src="../../assets/images/icons/mail.svg"
                      height="20"
                      width="20"
                      alt=""
                    />
                  </div>
                  <input
                    type="email"
                    id="email"
                    class="input block pl-10"
                    [ngClass]="{
                      'input-invalid':
                        signupForm.get('email')?.touched &&
                        signupForm.get('email')?.invalid
                    }"
                    placeholder="john.doe@acme.com"
                    formControlName="email"
                    autocomplete="off"
                    required
                  />
                </div>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('email')?.touched &&
                    signupForm.get('email')?.hasError('required')
                  "
                >
                  Email is required
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('email')?.touched &&
                    signupForm.get('email')?.hasError('pattern')
                  "
                >
                  Please enter valid email
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('email')?.touched &&
                    signupForm.get('email')?.hasError('maxlength')
                  "
                >
                  Max 50 characters are allowed
                </p>
              </div>
              <div>
                <label
                  for="phone-number"
                  class="block text-sm font-medium leading-6 text-gray-700"
                >
                  Contact Number
                </label>
                <div class="relative mt-1 rounded-md shadow-sm">
                  <div class="w-20 absolute inset-y-0 left-0 flex items-center">
                    <app-select
                      [control]="
                        formControl(signupForm.get('contactCountryCode'))
                      "
                      [isMultiple]="false"
                      [placeHolder]="'+49'"
                      [options]="countries"
                      [searchable]="true"
                      [class]="
                        'h-full rounded-lg border-0 ring-0 bg-transparent py-3 pl-3 pr-1 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-60'
                      "
                    >
                      <ng-template let-data let-options="options">
                        <ul
                          cdkListbox
                          aria-labelledby="example-toppings-label"
                          [formControl]="
                            formControl(signupForm.get('contactCountryCode'))
                          "
                          class="flex-1 w-full overflow-auto py-1 text-base focus:outline-none sm:text-sm"
                        >
                          <li
                            *ngFor="let countries of options"
                            class="text-gray-900 relative hover:bg-gray-100"
                            [hidden]="countries.hide"
                          >
                            <div
                              class="flex items-center w-full py-3 px-3 example-option"
                              [cdkOption]="countries.dial_code"
                            >
                              <img
                                src="assets/images/flags/{{
                                  countries.code.toLocaleLowerCase()
                                }}.svg"
                                alt=""
                                class="h-5 w-5 flex-shrink-0"
                              />
                              <span class="font-normal ml-3 block truncate">{{
                                countries.dial_code
                              }}</span>
                              <span class="font-normal ml-3 block truncate">{{
                                countries.name
                              }}</span>
                            </div>
                          </li>
                        </ul>
                      </ng-template>
                    </app-select>
                  </div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    placeholder="5559876543"
                    formControlName="contactNumber"
                    autocomplete="off"
                    class="input pl-20"
                    [ngClass]="{
                      'input-invalid':
                        (signupForm.get('contactNumber')?.touched &&
                          signupForm.get('contactNumber')?.invalid) ||
                        (signupForm.get('contactCountryCode')?.touched &&
                          signupForm.get('contactCountryCode')?.invalid)
                    }"
                  />
                </div>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('contactNumber')?.touched &&
                    signupForm.get('contactNumber')?.invalid
                  "
                >
                  Please enter 10 digit contact number
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('contactNumber')?.valid &&
                    signupForm.get('contactCountryCode')?.touched &&
                    signupForm.get('contactCountryCode')?.invalid
                  "
                >
                  Please select country code from the list
                </p>
                <div class="mt-2 flex text-sm items-center">
                  <input
                    id="optInForWhatsappUpdates"
                    type="checkbox"
                    value=""
                    formControlName="optInForWhatsappUpdates"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="optInForWhatsappUpdates"
                    class="ml-2 font-normal text-sm text-gray-500 dark:text-gray-300"
                    >Opt in for WhatsApp Updates</label
                  >
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="organization"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >Organization Name</label
                >
                <input
                  type="text"
                  id="organization"
                  placeholder="Acme Corporation"
                  formControlName="organizationName"
                  autocomplete="off"
                  required
                  class="input"
                  [ngClass]="{
                    'input-invalid':
                      signupForm.get('organizationName')?.touched &&
                      signupForm.get('organizationName')?.invalid
                  }"
                />
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('organizationName')?.touched &&
                    signupForm.get('organizationName')?.hasError('required')
                  "
                >
                  Organization Name is required
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('organizationName')?.touched &&
                    signupForm.get('organizationName')?.hasError('pattern')
                  "
                >
                  {{
                    signupForm.get("organizationName")?.errors?.[
                      "patternMsg"
                    ] || "Please enter valid name"
                  }}
                </p>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('organizationName')?.touched &&
                    signupForm.get('organizationName')?.hasError('maxlength')
                  "
                >
                  Max 50 characters are allowed
                </p>
              </div>
              <div>
                <label
                  for="numberOfEmployee"
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  >Number of Employee</label
                >
                <app-select
                  [control]="formControl(signupForm.get('numberOfEmployees'))"
                  [isMultiple]="false"
                  [placeHolder]="'1-10'"
                  [options]="numberOfEmployeesConst"
                  [searchable]="false"
                >
                  <ng-template let-data let-options="options">
                    <ul
                      cdkListbox
                      [formControl]="
                        formControl(signupForm.get('numberOfEmployees'))
                      "
                      aria-labelledby="example-toppings-label"
                      class="flex-1 w-full overflow-auto py-1 text-base focus:outline-none sm:text-sm"
                    >
                      <li
                        *ngFor="let emp of options"
                        class="text-gray-900 relative hover:bg-gray-100"
                      >
                        <div
                          class="flex items-center w-full py-3 px-3 example-option"
                          [cdkOption]="emp.value"
                        >
                          <span>{{ emp.value }}</span>
                        </div>
                      </li>
                    </ul>
                  </ng-template>
                </app-select>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('numberOfEmployees')?.touched &&
                    signupForm.get('numberOfEmployees')?.hasError('required')
                  "
                >
                  Please select a value from the list
                </p>
              </div>
              <div class="relative">
                <label
                  class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                  for="country"
                  >Country</label
                >
                <app-select
                  id="country"
                  [control]="formControl(signupForm.get('country'))"
                  [isMultiple]="false"
                  [placeHolder]="'Germany'"
                  [options]="countries"
                  [searchable]="true"
                >
                  <ng-template let-data let-options="options">
                    <ul
                      cdkListbox
                      [formControl]="formControl(signupForm.get('country'))"
                      aria-labelledby="example-toppings-label"
                      class="flex-1 w-full overflow-auto py-1 text-base focus:outline-none sm:text-sm"
                    >
                      <li
                        *ngFor="let countries of options"
                        class="text-gray-900 relative hover:bg-gray-100"
                        [hidden]="countries.hide"
                      >
                        <div
                          class="flex items-center w-full py-3 px-3 example-option"
                          [cdkOption]="countries.name"
                        >
                          <img
                            src="assets/images/flags/{{
                              countries.code.toLocaleLowerCase()
                            }}.svg"
                            alt=""
                            class="h-5 w-5 flex-shrink-0"
                          />
                          <span class="font-normal ml-3 block truncate">{{
                            countries.name
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </ng-template>
                </app-select>
                <p
                  class="input-err-msg"
                  *ngIf="
                    signupForm.get('country')?.touched &&
                    signupForm.get('country')?.invalid
                  "
                >
                  Please select country from the list
                </p>
              </div>
              <div class="sm:col-span-2 w-full">
                <div class="flex items-center text-sm mb-4">
                  <input
                    id="terms&condition"
                    type="checkbox"
                    value=""
                    formControlName="acceptTermsAndConditions"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 cursor-pointer dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="terms&condition"
                    class="ml-2 text-gray-500 dark:text-gray-300 cursor-pointer"
                    >I agree to
                    <a class="text-primary-500">Terms & Conditions</a></label
                  >
                </div>
              </div>
              <div class="sm:col-span-2">
                <button
                  type="submit"
                  appRipple
                  [disabled]="!signupForm.value.acceptTermsAndConditions"
                  class="btn-primary w-full px-5 relative overflow-hidden disabled:cursor-not-allowed disabled:bg-primary-300"
                >
                  Register
                </button>
                <div class="mt-2 text-gray-600 text-sm">
                  Already have an account?
                  <a
                    [routerLink]="['/login']"
                    class="font-medium text-primary-500"
                    >Log in</a
                  >
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="signupSuccessful()" class="flex flex-col items-center">
            <div class="bg-gray-200 rounded-full p-4 border-8 border-gray-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="text-green-400 h-12 w-12"
                [@popInOut]="'in'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h3 class="font-semibold text-2xl mt-4">
              Thank you for registering with us!
            </h3>
            <p class="mt-2 text-gray-800 text-base font-normal">
              We will get in touch with you shortly.
            </p>
          </div>
        </div>
        <div
          class="absolute bottom-0 w-full flex justify-between text-gray-700 text-base font-normal mt-2"
        >
          <img src="assets/images/icons/whatsapp.svg" alt="whatsapp" />
          <span class="text-sm">Privacy Policy | Help & Support</span>
        </div>
      </div>
    </div>
  </div>
</div>
