import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  TemplateRef,
} from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ToastComponent } from '../../ui-components/toast/toast.component';
import { ToastRef } from '../../ui-components/toast/toat-ref';
export type ToastType = 'warning' | 'info' | 'success' | 'error';
export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');

export interface ToastConfig {
  position?: {
    bottom: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    bottom: 20,
    right: 20,
  },
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
};
export class ToastData {
  type!: ToastType;
  title?: string;
  text?: string;
  template?: TemplateRef<any>;
}
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private lastToast!: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig,
  ) {}

  showToast(data: ToastData) {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    const componentRef = overlayRef.attach(toastPortal);
    if (data.template) {
      componentRef.instance.htmlTemplate = data.template;
    } else {
      if (data.title) {
        componentRef.instance.title = data.title;
      }
      if (data.text) {
        componentRef.instance.text = data.text;
      }
    }

    return toastRef;
  }

  getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .bottom(this.getPosition())
      .right(this.toastConfig?.position?.right + 'px');
  }

  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    if (
      lastToastIsVisible &&
      this.lastToast.getOverlay()?.getConfig()?.positionStrategy
    ) {
      const positionStrategy = this.lastToast.getOverlay()?.getConfig()
        ?.positionStrategy as any;
      const react = this.lastToast
        .getOverlay()
        .overlayElement.getBoundingClientRect();
      return `${
        +positionStrategy._bottomOffset.split('px')[0] + react.height
      }px`;
    } else {
      return `${this.toastConfig?.position?.bottom}px`;
    }
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }

  showAPIErrorToast(err: any) {
    this.showToast({
      type: 'error',
      title: 'Error',
      text: err?.error?.error?.message || 'Oops! Something went wrong. Please try again later.',
    })
  }
}
