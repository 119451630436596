import {
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { FormControl } from '@angular/forms';
import {
  trigger,
  transition,
  style,
  sequence,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [
    trigger('dropDownMenu', [
      transition(':enter', [
        style({ transform: 'scaleY(0)', overflow: 'hidden' }),
        sequence([animate('200ms', style({ transform: 'scaleY(1)' }))]),
      ]),

      transition(':leave', [
        style({ transform: 'scaleY(1)', overflow: 'hidden' }),
        sequence([animate('200ms', style({ transform: 'scaleY(0)' }))]),
      ]),
    ]),
  ],
})
export class SelectComponent {
  @ContentChild(TemplateRef) template!: TemplateRef<any>;
  @Input() isMultiple = false;
  @Input() placeHolder!: string;
  @Input() class = '';
  @Input() control!: FormControl;
  @Input() options: any[] = [];
  @Input() searchable = false;
  @Input() disabled = false;
  // new
  @ViewChild('input') input!: ElementRef;
  @ViewChild('div') div!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('content') content!: ViewContainerRef;
  @ViewChild(CdkPortal)
  public contentTemplate!: CdkPortal;
  protected overlayRef!: OverlayRef;
  public showing = false;
  hideAllOptions = false;
  position = 'top';

  constructor(protected overlay: Overlay) {}

  showDropdown() {
    if (this.showing) {
      this.hide();
    } else {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
      this.overlayRef.attach(this.contentTemplate);
      this.syncWidth();
      this.overlayRef.backdropClick().subscribe(() => this.hide());
      this.showing = true;
      setTimeout(() => {
        if (this.searchInput?.nativeElement) {
          this.searchInput.nativeElement.focus();
        }
      }, 0);
    }
  }

  public hide() {
    this.overlayRef.detach();
    this.showing = false;
    this.options.forEach((option) => {
      option.hide = false;
    });
    this.hideAllOptions = false;
  }

  @HostListener('window:resize')
  public onWinResize() {
    this.syncWidth();
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.isMultiple ? this.div : this.input)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    positionStrategy.positionChanges.subscribe((pos) => {
      this.position = pos.connectionPair.overlayY;
    });
    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }
    const refRect = this.isMultiple
      ? this.div.nativeElement?.getBoundingClientRect()
      : this.input.nativeElement?.getBoundingClientRect();
    this.overlayRef.updateSize({ minWidth: refRect.width });
  }

  searchOptions(event: any) {
    this.hideAllOptions = true;
    this.options.filter((option) => {
      const keys: string[] = Object.keys(option);
      for (const key of keys) {
        if (
          option?.[key]
            ?.toString()
            ?.toLowerCase()
            .startsWith(event.target.value.toLowerCase())
        ) {
          option.hide = false;
          this.hideAllOptions = false;
          break;
        } else {
          option.hide = true;
        }
      }
    });
  }
  removeValue(index: number) {
    this.control.setValue([
      ...this.control.value.slice(0, index),
      ...this.control.value.slice(index + 1),
    ]);
  }

  closeDropDown(event?: MouseEvent) {
    if (
      !this.isMultiple &&
      (event?.target as HTMLElement).tagName.toLowerCase() !== 'input'
    ) {
      this.hide();
    }
  }
}
