import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { authGuard } from './core/guards/auth.guard';
import { InviteComponent } from './invite/invite.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../app/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'login',
    component: AuthenticationComponent,
  },
  {
    path: 'forgot',
    component: AuthenticationComponent,
  },
  {
    path: 'reset',
    component: AuthenticationComponent,
  },
  {
    path: 'invite/:inviteId',
    component: InviteComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
