import { AbstractControl, ValidatorFn } from "@angular/forms";

export function passwordMatchValidator(newPasswordField: string, confirmPasswordField: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const newPassword = control.parent?.get(newPasswordField)?.value;
    const confirmPassword = control.value;
    if (newPassword !== confirmPassword) {
      control.get(confirmPasswordField)?.setErrors({ notSame: true });
      return { notSame: true };
    }
    return null;
  };
}
