import { ValidatorFn } from '@angular/forms';
import { RegValidationEnum, firstCharacterIsAlphabet } from '../consts/regex.const';

export function customPatternValid(config: { pattern: RegExp, msg: string }): ValidatorFn {
  return (control) => {
    const urlRegEx: RegExp = config.pattern;
    if (control.value && !control.value.match(urlRegEx)) {
      return {
        pattern: true,
        patternMsg: config.msg,
      };
    } else {
      return null;
    }
  };
}

export function validPatternCategory2(msg?: string): ValidatorFn {
  return (control) => {
    if (control.value) {
      if (!control.value.match(firstCharacterIsAlphabet)) {
        return {
          pattern: true,
          patternMsg: 'Value must start with a letter',
        };
      }
      if (!control.value.match(RegValidationEnum.CATEGORY_2)) {
        return {
          pattern: true,
          patternMsg: msg || `Valid characters are A-Z a-z 0-9 ' _ . () - and space`,
        };
      }
      // 'Please enter a value that starts with a letter and may contain alphanumeric characters, underscores, spaces, dots, parentheses, or hyphens.'
    }
    return null;
  };
}

export function validPatternCategory3(msg?: string): ValidatorFn {
  return (control) => {
    if (control.value) {
      if (!control.value.match(firstCharacterIsAlphabet)) {
        return {
          pattern: true,
          patternMsg: 'Value must start with a letter',
        };
      }
      if (!control.value.match(RegValidationEnum.CATEGORY_3)) {
        return {
          pattern: true,
          patternMsg: msg || 'Valid characters are A-Z a-z 0-9 and _',
        };
      }
    }
    return null;
  };
}
