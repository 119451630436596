<div>
  <div class="pt-2 pl-0.5 pr-0.5">
    <div class="grid grid-cols-4 gap-2">
      <div class="rounded-lg h-1" [ngClass]="[bar0]"></div>
      <div class="rounded-lg h-1" [ngClass]="[bar1]"></div>
      <div class="rounded-lg h-1" [ngClass]="[bar2]"></div>
      <div class="rounded-lg h-1" [ngClass]="[bar3]"></div>
    </div>
  </div>
  <p class="text-gray-600 font-normal mt-1 text-sm text-right pr-1">
    {{ message }}
  </p>
</div>
