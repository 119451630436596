import { environment } from '../../environments/environment';

export class Urls {
  static baseUrl = environment.apiUrl;
  static login = `${Urls.baseUrl}auth/login/`;
  static logout = `${Urls.baseUrl}auth/logout/`;
  static refreshAccessToken = `${Urls.baseUrl}auth/refresh/`;
  static forgotPassword = `${Urls.baseUrl}auth/forgot-password/`;
  static resetPassword = `${Urls.baseUrl}auth/reset-password/`;
  static invite = `${Urls.baseUrl}invite/`;
  static organizations = `${Urls.baseUrl}organizations/`;
  static organizationList = `${Urls.baseUrl}organizations/list/`;
  static users = `${Urls.baseUrl}users/`;
  static usersList = `${Urls.baseUrl}users/list/`;
  static userResetPassword = `${Urls.baseUrl}users/me/password/`;
  static leads = `${Urls.baseUrl}leads/`;
  static leadList = `${Urls.baseUrl}leads/list/`;
  static leadNewCount = `${Urls.baseUrl}leads/count/new/`;
  static org = `${Urls.baseUrl}org/`;

  static readonly openRoles = (params: { orgId: string }): string => {
    return `${Urls.baseUrl}org/${params.orgId}/op/roles/`;
  }
}
