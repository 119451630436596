// ! Keep in sync with backend
/** regex expression to verify all validation */
export class RegValidationEnum {
  /** category_1 it will only allow dash and single quote in name fields */
  static CATEGORY_1 = /^[a-zA-Z][a-zA-Z'-]*[a-zA-Z]$/;

  /** category_2 it will only allow dash, underscore, numbers, space, dot and round brackets (not any other special characters) */
  static CATEGORY_2 = /^[a-zA-Z][a-zA-Z'0-9_ .()-]*$/;

  /** category_3 it will only allow underscore and numbers (not allow any other special characters or space) */
  static CATEGORY_3 = /^[a-zA-Z][a-zA-Z0-9_]*[a-zA-Z0-9]$/;
}

export const firstCharacterIsAlphabet = /^[a-zA-Z]/;

export const emailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
