<div
  class="h-full w-full flex items-center justify-center bg-[#ffffffb3] absolute top-0"
>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
