<div class="flex justify-center bg-[#f7f7f7] w-full h-full overflow-y-scroll">
  <div class="rounded-lg bg-white sm:shadow p-6 h-full sm:h-fit m-auto sm:w-[75%]">
    <div
      class="grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 sm:gap-14"
    >
      <div
        class="flex bg-[#f7f7f7] row-span-1 rounded-lg justify-center align-middle"
      >
        <img [src]="'assets/images/' + path + '.svg'" alt="login" />
      </div>
      <div *ngIf="path === 'login'" class="flex items-center">
        <div class="w-full">
          <h3 class="font-semibold text-2xl">Welcome</h3>
          <p class="text-gray-800 text-base font-normal mt-2">
            Login to continue
          </p>

          <form
            [formGroup]="loginForm"
            (ngSubmit)="doLogin()"
            class="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4"
          >
            <div class="sm:col-span-2">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                >Email</label
              >
              <div class="relative">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <!-- email svg -->
                  <svg
                    class="w-[20px] h-[20px] text-gray-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"/>
                  </svg>
                </div>
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  [email]="true"
                  class="input pl-10"
                  [ngClass]="{
                    'input-invalid': fcLoginEmail.touched && fcLoginEmail.invalid
                  }"
                  placeholder="Your email"
                  required
                />
              </div>
              <p
                class="input-err-msg"
                *ngIf="
                  loginForm.get('email')?.touched &&
                  loginForm.get('email')?.hasError('required')
                "
              >
                Please enter email
              </p>
              <p
                class="input-err-msg"
                *ngIf="
                  loginForm.get('email')?.touched &&
                  loginForm.get('email')?.hasError('email')
                "
              >
                Please enter valid email.
              </p>
            </div>
            <div class="sm:col-span-2">
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                >Password</label
              >
              <div class="relative">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                <svg
                  class="w-[20px] h-[20px] text-gray-500 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 16 20"
                >
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"/>
                </svg>
                </div>
                <div
                  (click)="passwordToggle()"
                  class="absolute inset-y-0 right-0 flex items-center pr-3"
                  aria-hidden="true"
                >
                  <img
                    class="cursor-pointer"
                    [src]="
                      'assets/images/icons/' +
                      (showPassword() ? 'eye-slash' : 'eye') +
                      '.svg'
                    "
                    height="20"
                    width="20"
                    alt=""
                  />
                </div>
                <input
                  [type]="showPassword() ? 'text' : 'password'"
                  id="password"
                  formControlName="password"
                  class="input w-full pl-10 pr-10"
                  [ngClass]="{
                    'input-invalid':
                      loginForm.get('password')?.touched &&
                      !loginForm.get('password')?.value
                  }"
                  placeholder="Your password"
                  required
                />
              </div>
              <p
                class="input-err-msg"
                *ngIf="
                  loginForm.get('password')?.touched &&
                  loginForm.get('password')?.hasError('required')
                "
              >
                Please enter password
              </p>
            </div>
            <div class="sm:col-span-2">
              <div class="w-full flex justify-between text-sm">
                <div class="flex items-center">
                  <input
                    id="rememberMe"
                    type="checkbox"
                    formControlName="rememberMe"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="rememberMe"
                    class="ml-2 text-gray-400 dark:text-gray-300 text-xs cursor-pointer"
                    >Remember Me (uses cookies)</label
                  >
                </div>
                <a [routerLink]="['/forgot']" class="text-primary-500 text-xs">
                  Forgot password?
                </a>
              </div>
            </div>
            <div class="sm:col-span-2 mt-2">
              <button
                type="submit"
                appRipple
                [disabled]="authenticating()"
                class="btn-primary px-5 w-full relative overflow-hidden"
              >
                <svg
                  *ngIf="authenticating()"
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Login
              </button>
            </div>
            <div class="sm:col-span-2 mt-2 text-gray-500 text-xs">
              Don't have an account?
              <a [routerLink]="['/signup']" class="text-primary-500">Signup</a>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="path === 'forgot'" class="flex items-center">
        <div class="w-full" *ngIf="!forgotPasswordSubmitted()">
          <h3 class="font-semibold text-2xl">Forgot password?</h3>
          <p class="text-gray-800 text-base font-normal mt-2">
            No worries, we'll send you reset instructions.
          </p>

          <form
            [formGroup]="forgotPasswordForm"
            (ngSubmit)="forgotPassword()"
            class="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4"
          >
            <div class="sm:col-span-2">
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                >Email</label
              >
              <div class="relative">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <!-- email svg -->
                  <svg
                    class="w-[20px] h-[20px] text-gray-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"/>
                  </svg>
                </div>
                <input
                  type="email"
                  id="email"
                  formControlName="email"
                  class="input w-full pl-10"
                  [ngClass]="{
                    'input-invalid':
                      forgotPasswordForm.get('email')?.touched &&
                      forgotPasswordForm.get('email')?.invalid
                  }"
                  placeholder="Your email"
                  required
                />
              </div>
              <p
                class="input-err-msg"
                *ngIf="
                  forgotPasswordForm.get('email')?.touched &&
                  forgotPasswordForm.get('email')?.hasError('required')
                "
              >
                Please enter email.
              </p>
              <p
                class="input-err-msg"
                *ngIf="
                  forgotPasswordForm.get('email')?.touched &&
                  forgotPasswordForm.get('email')?.hasError('email')
                "
              >
                Please enter valid email.
              </p>
            </div>
            <div class="sm:col-span-2 mt-2">
              <button
                type="submit"
                appRipple
                [disabled]="authenticating()"
                class="btn-primary w-full px-5 relative overflow-hidden"
                >
                Reset Password
              </button>
            </div>
            <a
              [routerLink]="['/login']"
              class="sm:col-span-2 mt-3 text-gray-400 flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M16.0306 10.0003H4.36389M4.36389 10.0003L10.1972 15.8337M4.36389 10.0003L10.1972 4.16699"
                  stroke="#374151"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="ml-2 text-sm font-semibold text-gray-700"
                >Back to log in</span
              >
            </a>
          </form>
        </div>
        <div class="w-full" *ngIf="forgotPasswordSubmitted()">
          <h3 class="font-semibold mb-4 text-2xl">Check your email</h3>
          <p class="text-gray-800 text-base font-normal mb-8">
            We sent a password reset link to {{ forgotPasswordForm.value.email }}
          </p>
          <!--
            * Need to think about timeout solution first, user can keep clicking on button to send multiple emails

          <div class="sm:col-span-2 mb-8 text-gray-600 font-normal test-sm">
            Didn't receive the email?
            <button
              class="text-primary-500 font-semibold"
              (click)="forgotPassword()"
            >
              Click to resend
            </button>
          </div> -->
          <a
            [routerLink]="['/login']"
            class="sm:col-span-2 mt-2 text-gray-400 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M16.0306 10.0003H4.36389M4.36389 10.0003L10.1972 15.8337M4.36389 10.0003L10.1972 4.16699"
                stroke="#374151"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="ml-2 text-sm font-semibold text-gray-700 w-fit"
              >Back to log in</span
            >
          </a>
        </div>
      </div>
      <div *ngIf="path === 'reset'" class="flex items-center">
        <div class="w-full" *ngIf="!resetPasswordSubmitted()">
          <h3 class="font-semibold text-2xl">Set New Password</h3>
          <p class="text-gray-800 text-sm font-normal mt-2">
            Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.
          </p>

          <form
            [formGroup]="resetPasswordForm"
            (ngSubmit)="resetPassword()"
            class="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4"
          >
            <div class="sm:col-span-2">
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                >Password</label
              >
              <div>
                <div class="relative">
                  <div
                    (click)="passwordToggle()"
                    aria-hidden="true"
                    class="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    <img
                      class="cursor-pointer"
                      [src]="
                        'assets/images/icons/' +
                        (showPassword() ? 'eye-slash' : 'eye') +
                        '.svg'
                      "
                      height="20"
                      width="20"
                      alt=""
                    />
                  </div>
                  <input
                    [type]="showPassword() ? 'text' : 'password'"
                    id="password"
                    class="input pr-10"
                    [ngClass]="{
                      'input-invalid':
                      ((resetPasswordForm.get('password')?.touched &&
                      resetPasswordForm.get('password')?.invalid) || !isStrongPassword)
                    }"
                    placeholder="••••••••"
                    required
                    formControlName="password"
                  />
                </div>
                <app-password-strength
                  [passwordToCheck]="resetPasswordForm.value.password"
                  (passwordStrength)="passwordStrength($event)"
                ></app-password-strength>
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="matchPassword"
                class="block mb-2 text-sm font-medium text-gray-700 dark:text-white"
                >Re-Enter Password</label
              >
              <div>
                <input
                  type="password"
                  id="matchPassword"
                  class="input"
                  [ngClass]="{
                    'input-invalid':
                      resetPasswordForm.get('matchPassword')?.touched &&
                      resetPasswordForm.get('matchPassword')?.invalid
                  }"
                  placeholder="••••••••"
                  required
                  formControlName="matchPassword"
                />
              </div>
              <p
                class="input-err-msg"
                *ngIf="
                  resetPasswordForm.get('matchPassword')?.touched &&
                  resetPasswordForm.get('matchPassword')?.hasError('required')
                "
              >
                Please re-enter password.
              </p>
              <p
                class="input-err-msg"
                *ngIf="
                  resetPasswordForm.get('matchPassword')?.touched &&
                  resetPasswordForm.get('matchPassword')?.hasError('notSame')
                "
              >
                Passwords do not match.
              </p>
            </div>
            <div class="sm:col-span-2 mt-2">
              <button
                type="submit"
                appRipple
                [disabled]="authenticating()"
                class="btn-primary w-full px-5 relative overflow-hidden"
              >
                Reset Password
              </button>
            </div>
            <a
              [routerLink]="['/login']"
              class="sm:col-span-2 mt-2 text-gray-400 flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M16.0306 10.0003H4.36389M4.36389 10.0003L10.1972 15.8337M4.36389 10.0003L10.1972 4.16699"
                  stroke="#374151"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="ml-2 text-sm font-semibold text-gray-700"
                >Go to log in</span
              >
            </a>
          </form>
        </div>
        <div class="w-full" *ngIf="resetPasswordSubmitted()">
          <h3 class="font-semibold mb-4 text-2xl">Password Reset</h3>
          <p class="text-gray-800 text-base font-normal mb-8">
            Your password has been successfully reset. Please login to continue.
          </p>
          <a
            [routerLink]="['/login']"
            class="sm:col-span-2 mt-2 text-gray-400 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M16.0306 10.0003H4.36389M4.36389 10.0003L10.1972 15.8337M4.36389 10.0003L10.1972 4.16699"
                stroke="#374151"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="ml-2 text-sm font-semibold text-gray-700"
              >Go to log in</span
            >
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-between text-gray-700 text-base font-normal pt-6">
      <img src="assets/images/icons/whatsapp.svg" alt="whatsapp" />
      <span>Privacy Policy | Help & Support</span>
    </div>
  </div>
</div>
