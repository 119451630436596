export const numberOfEmployees = [
  { value: '1-10' },
  { value: '11-50' },
  { value: '51-200' },
  { value: '201-500' },
  { value: '501-1000' },
  { value: '1001-5000' },
  { value: '5001-10000' },
  { value: '10000+' },
];

