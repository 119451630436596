<div class="input-wrapper bg-transparent relative">
  <div
    *ngIf="isMultiple"
    #div
    tabindex="0"
    (click)="showDropdown()"
    (keydown.enter)="showDropdown()"
    (keydown.escape)="closeDropDown()"
    class="flex w-full rounded-lg border-0 py-2 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6 flex-wrap"
  >
    <ng-container *ngIf="control.value.length; else PLACEHOLDER">
      <span
        *ngFor="let value of control.value; index as i"
        class="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 leading-none mr-1 m-px"
      >
        {{ value }}
        <button
          type="button"
          class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20"
          (click)="removeValue(i)"
        >
          <span class="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            class="h-3.5 w-3.5 stroke-indigo-700/50 group-hover:stroke-indigo-700/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span class="absolute -inset-1"></span>
        </button>
      </span>
    </ng-container>
    <ng-template #PLACEHOLDER>
      <span class="text-gray-400 h-6">{{ placeHolder }}</span>
    </ng-template>
  </div>
  <input
    *ngIf="!isMultiple"
    #input
    (click)="showDropdown()"
    (keydown.enter)="showDropdown()"
    (keydown.escape)="closeDropDown()"
    [value]="control.value"
    readonly
    autocomplete="off"
    class="input focus-visible:outline-none {{ class }}"
    placeholder="{{ placeHolder }}"
    [disabled]="disabled"
    [ngClass]="{
      'input-disabled': disabled
    }"
  />
  <svg
    class="absolute top-2.5 right-2 h-5 w-5 text-gray-400"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
      clip-rule="evenodd"
    />
  </svg>

  <div id="options">
    <ng-template cdkPortal>
      <div
        [@dropDownMenu]
        [style.transformOrigin]="position"
        class="flex flex-col bg-white w-full max-h-56 shadow-xl rounded-md py-2 border border-gray-300"
        (click)="closeDropDown($event)"
        (keydown.escape)="closeDropDown()"
      >
        <div class="px-2 pb-2 border-b-2 border-gray-100" *ngIf="searchable">
          <input
            #searchInput
            (keyup)="searchOptions($event)"
            type="text"
            class="input py-1"
            tabIndex="0"
            placeholder="search"
            autocomplete="off"
          />
        </div>
        <ng-container *ngIf="!hideAllOptions; else noOptions">
          <ng-container
            *ngTemplateOutlet="
              template;
              context: { $implicit: 'country', options }
            "
          ></ng-container>
        </ng-container>
        <ng-template #noOptions>
          <div
            class="flex justify-center text-gray-500 hover:bg-gray-100 w-full py-3 px-3"
          >
            No match found
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
